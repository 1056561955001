import React, { useState, useEffect } from 'react';
import api from './api';
import { Box, Typography, Paper, CircularProgress, Button, Chip } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  background: 'linear-gradient(145deg, #1e2a3a 0%, #2a3f55 100%)',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(4px)',
  borderRadius: '10px',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

const ScrollableContent = styled(Box)({
  overflowY: 'auto',
  flexGrow: 1,
  maxHeight: '400px',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '4px',
  },
});

const OntologyViewer = ({ searchTerm }) => {
  const [ontologyContents, setOntologyContents] = useState('');
  const [ontologyMetadata, setOntologyMetadata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [displayedLines, setDisplayedLines] = useState(100);
  const [pendingUpdates, setPendingUpdates] = useState([]);

  useEffect(() => {
    const fetchOntologyContents = async () => {
      try {
        setLoading(true);
        setError(null);
        const [ontologyResponse, pendingUpdatesResponse] = await Promise.all([
          api.get('/api/system/ontology-contents'),
          api.get('/api/system/pending-ontology-updates')
        ]);
        setOntologyContents(ontologyResponse.data.contents);
        setOntologyMetadata(ontologyResponse.data.metadata);
        setPendingUpdates(pendingUpdatesResponse.data);
      } catch (error) {
        console.error('Failed to fetch ontology data:', error);
        setError('Failed to load ontology data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchOntologyContents();
  }, []);

  const loadMoreLines = () => {
    setDisplayedLines(prevLines => prevLines + 100);
  };

  const displayContent = () => {
    if (!ontologyContents) return '';
    const lines = ontologyContents.split('\n');
    let filteredLines = lines;
    if (searchTerm) {
      filteredLines = lines.filter(line => line.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    return filteredLines.slice(0, displayedLines).join('\n');
  };

  return (
    <StyledPaper elevation={3}>
      {ontologyMetadata && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'secondary.light' }}>
            Last Updated: {ontologyMetadata.lastUpdated ? new Date(ontologyMetadata.lastUpdated).toLocaleString() : 'N/A'}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'secondary.light' }}>
            Recent Additions: {ontologyMetadata.recentAdditions || 'N/A'}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'secondary.light', mt: 1 }}>
            Pending Updates: {pendingUpdates.length}
          </Typography>
          <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap' }}>
            {pendingUpdates.slice(0, 5).map((update, index) => (
              <Chip
                key={index}
                label={update.concept}
                sx={{ m: 0.5 }}
                color="primary"
                variant="outlined"
              />
            ))}
            {pendingUpdates.length > 5 && (
              <Chip
                label={`+${pendingUpdates.length - 5} more`}
                sx={{ m: 0.5 }}
                color="primary"
                variant="outlined"
              />
            )}
          </Box>
        </Box>
      )}
      <ScrollableContent>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height={200}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <>
            <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', color: '#ecf0f1' }}>
              {displayContent()}
            </pre>
            {displayedLines < ontologyContents.split('\n').length && (
              <Button onClick={loadMoreLines} variant="outlined" sx={{ mt: 2 }}>
                Load More
              </Button>
            )}
          </>
        )}
      </ScrollableContent>
    </StyledPaper>
  );
};

export default OntologyViewer;