import React, { useState, useEffect } from 'react';
import api from './api';
import { Box, Typography, Grid, Button, TextField, Paper, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { Bar, Line } from 'react-chartjs-2';
import OntologyViewer from './OntologyViewer';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  background: 'linear-gradient(145deg, #1e2a3a 0%, #2a3f55 100%)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(4px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const GlowingButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  color: 'white',
  padding: '0 30px',
  '&:hover': {
    boxShadow: '0 6px 10px 4px rgba(33, 203, 243, .5)',
  },
}));

const Ontology = () => {
  const [pendingUpdates, setPendingUpdates] = useState([]);
  const [updateHistory, setUpdateHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const [pendingUpdatesRes, updateHistoryRes] = await Promise.all([
        api.get('/api/system/pending-ontology-updates'),
        api.get('/api/system/ontology-update-history')
      ]);
  
      setPendingUpdates(pendingUpdatesRes.data);
      setUpdateHistory(updateHistoryRes.data);
    } catch (error) {
      console.error('Failed to fetch ontology data:', error);
      setError('Failed to load ontology data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSavePendingUpdates = async () => {
    try {
      setIsLoading(true);
      await api.post('/api/system/save-pending-ontology-updates');
      await fetchData();
    } catch (error) {
      console.error('Failed to save pending updates:', error);
      setError('Failed to save pending updates. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const pendingUpdatesChartData = {
    labels: pendingUpdates.map(update => update.concept),
    datasets: [{
      label: 'Frequency',
      data: pendingUpdates.map(update => update.frequency),
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    }]
  };

  const updateHistoryChartData = {
    labels: updateHistory.map(update => new Date(update.date).toLocaleDateString()),
    datasets: [{
      label: 'Concepts Added',
      data: updateHistory.map(update => update.conceptsAdded),
      borderColor: 'rgba(255, 99, 132, 1)',
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      fill: true,
    }]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          color: 'rgba(255, 255, 255, 0.7)',
        },
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.7)',
        },
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.7)',
        },
      },
    },
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3, backgroundColor: '#121212' }}>
      <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', mb: 4 }}>
        Ontology Management
      </Typography>
      {error && (
        <Typography color="error" sx={{ mt: 2, mb: 2 }}>
          {error}
        </Typography>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" sx={{ color: 'primary.light' }}>Pending Updates</Typography>
              <Tooltip title="Save Pending Updates">
                <span>
                  <Button
                    onClick={handleSavePendingUpdates}
                    disabled={pendingUpdates.length === 0 || isLoading}
                    startIcon={<SaveIcon />}
                    variant="contained"
                    color="primary"
                  >
                    {isLoading ? 'Saving...' : 'Save'}
                  </Button>
                </span>
              </Tooltip>
            </Box>
            <Box sx={{ flexGrow: 1, height: 300 }}>
              <Bar data={pendingUpdatesChartData} options={chartOptions} />
            </Box>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" sx={{ color: 'primary.light' }}>Update History</Typography>
              <Tooltip title="Refresh Data">
                <IconButton onClick={fetchData} disabled={isLoading}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ flexGrow: 1, height: 300 }}>
              <Line data={updateHistoryChartData} options={chartOptions} />
            </Box>
          </StyledPaper>
        </Grid>
        <Grid item xs={12}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom sx={{ color: 'primary.light' }}>Ontology Viewer</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search ontology..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </Box>
            <OntologyViewer searchTerm={searchTerm} key={pendingUpdates.length} />
          </StyledPaper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Ontology;