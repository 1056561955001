import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Suppress ResizeObserver loop error
const suppressResizeObserverLoopError = () => {
  const resizeObserverLoopErrRe = /^ResizeObserver loop completed with undelivered notifications\.$/;
  
  // Suppress console errors
  const originalConsoleError = console.error;
  console.error = (...args) => {
    if (args.length > 0 && resizeObserverLoopErrRe.test(args[0])) {
      return;
    }
    originalConsoleError.apply(console, args);
  };

  // Suppress unhandled promise rejections
  window.addEventListener('unhandledrejection', (event) => {
    if (resizeObserverLoopErrRe.test(event.reason?.message)) {
      event.preventDefault();
    }
  });

  // Suppress error logs
  const originalOnError = window.onerror;
  window.onerror = (message, source, lineno, colno, error) => {
    if (resizeObserverLoopErrRe.test(message)) {
      return true; // Suppress the error
    }
    return originalOnError?.(message, source, lineno, colno, error);
  };
};

suppressResizeObserverLoopError();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);