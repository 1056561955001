// PdfCard.js

import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Checkbox, Skeleton, IconButton } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';
import api from './api';

const PdfCard = React.memo(({ pdf, onClick, onSelect, isSelected }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const [previewError, setPreviewError] = useState(false);
  const [previewFetched, setPreviewFetched] = useState(false);

  const fetchPreviewImage = useCallback(async () => {
    if (!pdf.filename || previewFetched) return;

    try {
      const response = await api.get(`/api/data/preview/${encodeURIComponent(pdf.filename)}`, { responseType: 'blob' });
      const url = URL.createObjectURL(response.data);
      setPreviewUrl(url);
      setPreviewError(false);
    } catch (error) {
      console.error(`Failed to fetch preview image for ${pdf.filename}`, error);
      setPreviewError(true);
    } finally {
      setPreviewFetched(true);
    }
  }, [pdf.filename, previewFetched]);

  useEffect(() => {
    fetchPreviewImage();
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [fetchPreviewImage, previewUrl]);

  const handleCardClick = (event) => {
    if (!event.target.closest('.MuiCheckbox-root') && !event.target.closest('.MuiIconButton-root')) {
      onClick(pdf);
    }
  };

  if (!pdf || !pdf.filename) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 2,
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 2,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'action.hover',
        },
        position: 'relative',
      }}
      onClick={handleCardClick}
    >
      <Box sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1, display: 'flex' }}>
        <Checkbox
          checked={isSelected}
          onChange={(e) => {
            e.stopPropagation();
            onSelect(pdf);
          }}
          onClick={(e) => e.stopPropagation()}
        />
      </Box>
      <Box sx={{ width: '100%', height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 2 }}>
        {!imageLoaded && !previewError && (
          <Skeleton variant="rectangular" width="100%" height={200} />
        )}
        {previewError ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <PictureAsPdfIcon sx={{ fontSize: 60, color: 'text.secondary' }} />
            <Typography variant="caption" color="text.secondary">Preview not available</Typography>
          </Box>
        ) : (
          <img
            src={previewUrl}
            alt={pdf.filename}
            style={{ 
              maxWidth: '100%', 
              maxHeight: '100%', 
              objectFit: 'contain', 
              display: imageLoaded ? 'block' : 'none' 
            }}
            onLoad={() => setImageLoaded(true)}
            onError={() => setPreviewError(true)}
          />
        )}
      </Box>
      <Typography variant="subtitle1" noWrap sx={{ maxWidth: '100%' }}>{pdf.filename}</Typography>
    </Box>
  );
});

export default PdfCard;