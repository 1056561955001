import axios from 'axios';
import { auth } from './firebase';

const api = axios.create();

api.interceptors.request.use(async (config) => {
  if (!config.url.includes('/login')) {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;