import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Typography, Tooltip, Collapse, Box } from '@mui/material';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExploreIcon from '@mui/icons-material/Explore';
import PerformanceIcon from '@mui/icons-material/TrendingUp';
import SystemIcon from '@mui/icons-material/Memory';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SettingsIcon from '@mui/icons-material/Settings';
import LightbulbIcon from '@mui/icons-material/Lightbulb'; 
import CategoryIcon from '@mui/icons-material/Category';
import LogoutIcon from '@mui/icons-material/Logout';
import { NavLink } from 'react-router-dom';
import { ReactComponent as LogoIcon } from './logodv.svg';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: 240,
    overflowX: 'hidden',
    backgroundColor: '#000000',  
    color: theme.palette.text.primary,
  },
}));

const UserInfoCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  marginBottom: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  '&.active': {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
}));

const LogoutButton = styled(ListItem)(({ theme }) => ({
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    right: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 0, 0.2)',
    },
}));

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen, onLogout }) => {
    const [version, setVersion] = useState('');
    const [userInfo, setUserInfo] = useState({});
    const [userInfoOpen, setUserInfoOpen] = useState(false);

    useEffect(() => {
        fetch('/version')
            .then(response => response.json())
            .then(data => setVersion(data.version))
            .catch(error => console.log('Error fetching version:', error));
    }, []);

    useEffect(() => {
        fetch('/api/data/user-info')
            .then(response => response.json())
            .then(data => {
                if (!data.error) {
                    setUserInfo(data);
                } else {
                    setUserInfo({});
                }
            })
            .catch(error => console.log('Error fetching user info:', error));
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const toggleUserInfo = () => {
        setUserInfoOpen(!userInfoOpen);
    };

    const drawerContent = (
        <>
            <List>
                {userInfo.username && (
                    <ListItem>
                        <UserInfoCard>
                            <Typography variant="h6" sx={{ color: 'primary.main', mb: 1 }}>
                                {userInfo.display_name}
                            </Typography>
                            <Box>
                                <Typography
                                    variant="body2"
                                    onClick={toggleUserInfo}
                                    sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                >
                                    User Info <ExpandMoreIcon />
                                </Typography>
                                <Collapse in={userInfoOpen} timeout="auto" unmountOnExit>
                                    <Typography variant="body2">Username: {userInfo.username}</Typography>
                                    <Typography variant="body2">Email: {userInfo.email}</Typography>
                                    <Typography variant="body2">Access Level: {userInfo.access_level}</Typography>
                                    <Typography variant="body2">Permissions: {userInfo.permissions.join(', ')}</Typography>
                                </Collapse>
                            </Box>
                        </UserInfoCard>
                    </ListItem>
                )}
                <StyledListItem button component={NavLink} to="/">
                    <ListItemIcon><LogoIcon /></ListItemIcon>
                </StyledListItem>
                <Tooltip title="Explore Data Hub" placement="right">
                    <StyledListItem button component={NavLink} to="/datahub">
                        <ListItemIcon><PerformanceIcon /></ListItemIcon>
                        <ListItemText primary="Data Hub" />
                    </StyledListItem>
                </Tooltip>
                <Tooltip title="AI assistants" placement="right">
                    <StyledListItem button component={NavLink} to="/assistant">
                        <ListItemIcon><ExploreIcon /></ListItemIcon>
                        <ListItemText primary="AI Assistant" />
                    </StyledListItem>
                </Tooltip>
                <Tooltip title="Ontology Management" placement="right">
                <StyledListItem button component={NavLink} to="/ontology">
                    <ListItemIcon><CategoryIcon /></ListItemIcon>
                    <ListItemText primary="Ontology" />
                </StyledListItem>
                </Tooltip>
                <Tooltip title="System dashboard" placement="right">
                    <StyledListItem button component={NavLink} to="/system">
                        <ListItemIcon><SystemIcon /></ListItemIcon>
                        <ListItemText primary="System" />
                    </StyledListItem>
                </Tooltip>
                <Tooltip title="Prompt Guidance" placement="right">
                    <StyledListItem button component={NavLink} to="/prompt-guidance">
                        <ListItemIcon><LightbulbIcon /></ListItemIcon>
                        <ListItemText primary="Prompt Guidance" />
                    </StyledListItem>
                </Tooltip>
                <StyledListItem>
                    <ListItemIcon><LocalOfferIcon /></ListItemIcon>
                    <ListItemText>
                        <Typography variant="caption" display="block" gutterBottom>
                            Version: {version}
                        </Typography>
                    </ListItemText>
                </StyledListItem>
            </List>
            <Tooltip title="Logout" placement="right">
                <LogoutButton onClick={onLogout}>
                    <ListItemIcon>
                        <LogoutIcon sx={{ color: 'error.main' }} />
                    </ListItemIcon>
                    <ListItemText primary="Logout" sx={{ color: 'error.main' }} />
                </LogoutButton>
            </Tooltip>
        </>
    );

    return (
        <div>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={toggleSidebar}
                sx={{
                    position: 'fixed',
                    top: 8,
                    left: isSidebarOpen ? 240 : 10,
                    zIndex: 1300,
                    pl: isSidebarOpen ? 0 : 2,
                }}
            >
                {isSidebarOpen ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
            <StyledDrawer
                variant="persistent"
                open={isSidebarOpen}
                onClose={toggleSidebar}
            >
                {drawerContent}
            </StyledDrawer>
        </div>
    );
};

export default Sidebar;