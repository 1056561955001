// frontend/src/ProgressUpdates.js

import React, { useState, useEffect } from 'react';
import api from './api';
import { Box, Typography, LinearProgress, List, ListItem } from '@mui/material';
import { styled } from '@mui/system';

const ProgressContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  background: 'linear-gradient(145deg, rgba(30,40,50,0.4) 0%, rgba(40,50,60,0.4) 100%)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(4px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 2,
  backgroundColor: 'rgba(255,255,255,0.1)',
  '& .MuiLinearProgress-bar': {
    borderRadius: 2,
    background: 'linear-gradient(90deg, #00d2ff 0%, #3a7bd5 100%)',
  },
}));

const ProgressText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 'bold',
  fontSize: '0.875rem',
  marginTop: theme.spacing(1),
  textShadow: '0 0 10px rgba(0,210,255,0.5)',
}));

const StepsList = styled(List)(({ theme }) => ({
  padding: 0,
  marginTop: theme.spacing(1),
}));

const StepItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0.5, 0),
  color: theme.palette.common.white,
  fontSize: '0.75rem',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateX(5px)',
  },
}));

function ProgressUpdates({ processId, onComplete, endpoint }) {
    const [progress, setProgress] = useState(null);
    const [progressHistory, setProgressHistory] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProgress = async () => {
            try {
                const response = await api.get(`/api/${endpoint}/${processId}`);
                if (Array.isArray(response.data) && response.data.length > 0) {
                    // Remove duplicates while preserving order
                    const uniqueUpdates = response.data.reduce((acc, current) => {
                        const x = acc.find(item => item.message === current.message);
                        if (!x) {
                            return acc.concat([current]);
                        } else {
                            return acc;
                        }
                    }, []);

                    setProgressHistory(uniqueUpdates);
                    const latestProgress = uniqueUpdates[uniqueUpdates.length - 1];
                    setProgress(latestProgress);
                    if (latestProgress.status === "Completed" || latestProgress.status === "Error") {
                        onComplete(latestProgress.status === "Completed");
                    }
                } else if (response.data && typeof response.data === 'object') {
                    // Handle single update (for compatibility with AI completions)
                    setProgress(response.data);
                    setProgressHistory(prevHistory => {
                        if (!prevHistory.some(item => item.message === response.data.message)) {
                            return [...prevHistory, response.data];
                        }
                        return prevHistory;
                    });
                    if (response.data.status === "Completed" || response.data.status === "Error") {
                        onComplete(response.data.status === "Completed");
                    }
                } else {
                    throw new Error("Invalid response format");
                }
            } catch (err) {
                console.error('Error fetching progress:', err);
                setError("Failed to fetch progress. Please try again.");
            }
        };

        const intervalId = setInterval(fetchProgress, 1000);
        return () => clearInterval(intervalId);
    }, [processId, onComplete, endpoint]);

    if (error) {
        return (
            <Box sx={{ mt: 2, p: 2, bgcolor: 'error.main', color: 'error.contrastText', borderRadius: 1 }}>
                <Typography variant="body1">{error}</Typography>
            </Box>
        );
    }

    if (!progress) {
        return null;
    }

    return (
        <ProgressContainer>
            <StyledLinearProgress variant="determinate" value={progress.percentage || 0} />
            <ProgressText variant="body2" align="left">
                {progress.status || 'Processing...'}
            </ProgressText>
            <StepsList>
                {progressHistory.map((step, index) => (
                    <StepItem key={index} style={{ color: step.status === "Error" ? 'red' : 'inherit' }}>
                        {step.message}
                    </StepItem>
                ))}
            </StepsList>
        </ProgressContainer>
    );
}

export default React.memo(ProgressUpdates);