// frontend/src/UploadData.js

import React, { useState, useCallback, useContext } from 'react';
import api from './api';
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppContext } from './AppContext';
import ProgressUpdates from './ProgressUpdates';
import { motion } from 'framer-motion';

const UploadContainer = styled(motion.div)(({ theme }) => ({
  background: 'linear-gradient(135deg, rgba(25, 118, 210, 0.1) 0%, rgba(25, 118, 210, 0.2) 100%)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(8px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '400px',
  position: 'relative',
  overflow: 'hidden',
}));

const FileInput = styled('input')({
  display: 'none',
});

const UploadButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #2196F3, #21CBF3)',
  border: 0,
  borderRadius: '50px',
  boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  margin: theme.spacing(2),
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(45deg, #21CBF3, #2196F3)',
    boxShadow: '0 6px 10px 4px rgba(33, 203, 243, .3)',
    transform: 'translateY(-2px)',
  },
}));

const FileList = styled(List)(({ theme }) => ({
  width: '100%',
  maxHeight: '200px',
  overflowY: 'auto',
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: theme.shape.borderRadius,
}));

const FileItem = styled(ListItem)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.15)',
  },
}));

const UploadData = ({ onClose }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadMessage, setUploadMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const { setRefreshDataHub } = useContext(AppContext);
  const [batchId, setBatchId] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleUploadPdfs = useCallback(async () => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      selectedFiles.forEach((file) => formData.append('pdfs', file));
      const response = await api.post('/api/system/upload_pdfs', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setBatchId(response.data.batch_id);
      setUploadMessage("Upload process started. Monitoring progress...");
    } catch (error) {
      console.error('Error during PDF upload:', error);
      setUploadMessage(error.response?.data?.error || 'Error uploading PDFs.');
      setIsUploading(false);
      setSnackbar({ open: true, message: 'Error uploading PDFs', severity: 'error' });
    }
  }, [selectedFiles]);

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles(files => files.filter((_, i) => i !== index));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const handleUploadComplete = useCallback((success) => {
    setIsUploading(false);
    if (success) {
      setSnackbar({ open: true, message: 'PDFs uploaded successfully', severity: 'success' });
      setRefreshDataHub(prev => !prev);
      onClose();
    } else {
      setSnackbar({ open: true, message: 'Error uploading PDFs', severity: 'error' });
    }
    setBatchId(null);
  }, [setRefreshDataHub, onClose]);

  return (
    <UploadContainer
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.3 }}
    >
      <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold', marginBottom: 3 }}>
        Upload PDFs
      </Typography>
      <FileInput
        type="file"
        multiple
        accept=".pdf"
        onChange={handleFileChange}
        id="pdf-file-input"
      />
      <label htmlFor="pdf-file-input">
        <UploadButton
          component="span"
          startIcon={<CloudUploadIcon />}
          disabled={isUploading}
        >
          Select PDFs
        </UploadButton>
      </label>
      {selectedFiles.length > 0 && (
        <FileList>
          {selectedFiles.map((file, index) => (
            <FileItem key={index}>
              <ListItemText primary={file.name} />
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleRemoveFile(index)}
              >
                <DeleteIcon />
              </IconButton>
            </FileItem>
          ))}
        </FileList>
      )}
      <UploadButton
        onClick={handleUploadPdfs}
        disabled={isUploading || selectedFiles.length === 0}
        startIcon={<CloudUploadIcon />}
      >
        {isUploading ? 'Uploading...' : 'Upload PDFs'}
      </UploadButton>
      {isUploading && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress />
        </Box>
      )}
      {isUploading && batchId && (
        <Box sx={{ mt: 2, width: '100%' }}>
          <ProgressUpdates 
            processId={batchId}
            onComplete={handleUploadComplete}
            endpoint="system/upload_progress"
          />
          {uploadMessage && (
            <Typography variant="body2" sx={{ mt: 2, color: 'secondary.main', fontWeight: 'bold' }}>
              {uploadMessage}
            </Typography>
          )}
        </Box>
      )}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%', boxShadow: 4 }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </UploadContainer>
  );
};

export default React.memo(UploadData);