// AIModelToggle.js
import React from 'react';
import { Select, MenuItem, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DiamondIcon from '@mui/icons-material/Diamond';

const StyledSelect = styled(Select)(({ theme }) => ({
  minWidth: 150,
  height: 36,
  '& .MuiSelect-select': {
    paddingY: theme.spacing(0.5),
    paddingX: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.875rem',
  },
}));

const ModelIcon = styled('span')(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  display: 'inline-flex',
  '& svg': {
    fontSize: '1rem',
  },
}));

const modelInfo = {
  'gpt-4o': {
    name: 'GPT-4o',
    description: 'OpenAIs high-intelligence flagship model for complex, multistep tasks. Text and image input, text output.',
    tokenLimit: 128000,
    icon: <AutoAwesomeIcon />,
  },
  'gemini-1.5-pro-002': {
    name: 'Gemini 1.5 Pro',
    description: 'Googles most advanced multimodal model. Text, image, audio, video, and PDF file input, text output. This model supports long-context understanding up to the maximum input token limit.',
    tokenLimit: 2097152,
    icon: <DiamondIcon />,
  },
};

const AIModelToggle = ({ selectedModel, setSelectedModel }) => {
  return (
    <StyledSelect
      value={selectedModel}
      onChange={(event) => setSelectedModel(event.target.value)}
      displayEmpty
      inputProps={{ 'aria-label': 'Select AI model' }}
    >
      {Object.entries(modelInfo).map(([modelKey, modelData]) => (
        <MenuItem key={modelKey} value={modelKey}>
          <Tooltip
            title={
              <React.Fragment>
                <Typography color="inherit" variant="body2">{modelData.name}</Typography>
                <Typography variant="caption">{modelData.description}</Typography>
                <Typography variant="caption">Token Limit: {Math.floor(modelData.tokenLimit * 0.9).toLocaleString()}</Typography>
              </React.Fragment>
            }
            arrow
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <ModelIcon>{modelData.icon}</ModelIcon>
              <span style={{ fontSize: '0.875rem' }}>{modelData.name}</span>
            </span>
          </Tooltip>
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default React.memo(AIModelToggle);
